import React from 'react'
import Layout from '../components/Layout/layout'
import styled from 'styled-components'

const Div = styled.div`    
    p{
        color: var(--textcolor);
        font-family: "Manrope", sans-serif;
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5;
        
    }
    h1{
        font-family: "Barlow Condensed";
            margin-bottom: 1.5rem;
            font-size: 40px !important;
            line-height: 1.5;
            font-weight: 600;
            color: var(--textcolor);
        }
`
const TermsOfUse = () => {
    return (
        <Layout>
            <Div >
                <div className='container py-5'>
                    <p>Welcome to Iwebcode terms &amp; conditions. We have kept clarity about our terms and conditions. You can find the terms and conditions below.</p>
                    <h1>Web hosting Agreement and terms of services –&nbsp;</h1>
                    <p>
                        Iwebcode – These policies and terms of services are compulsory for signing up with us and using hosting services. Please ensure that you read the policies carefully and agree to the same before signing up. You can contact us anytime if you have any questions about the services or policies. Iwebcode reserves the right to change the use policies and terms anytime. Iwebbcode is serving a huge community of clients and our priority is our every client’s security. We try our best to make sure that you get the best services from us.
                    </p>
                    <p>We have the following guidelines (Iwebcode is not liable for any refund in case we find any transgression for each account.</p>
                    <h1>Spam policy –&nbsp;</h1>
                    <p>
                        Iwebcode reserves the right to change the structure of package/plans and costs anytime. Prices and services listed on our website can be changed at any moment and without notice. Iwebcode also has complete discretion over price increases and this would work for new deals but it may not work for orders placed before the announcement.
                    </p>
                    <h1>Late/Non-payment –&nbsp;</h1>
                    <p>
                        An administrative fee of Rupees – will be charged in cases of reactivation of cancelled accounts. All technical support will be revoked if your account is 7 days past due. Your site and other services will be suspended if your account is 14 days past due. Your sites and data will be erased from all of our systems if your account is 30 days overdue.
                    </p>
                    <p>
                        We will make every effort to contact you via all available methods; however, it is your (client’s) duty to keep accurate and up-to-current information such as an address, contact, and billing information up to date. If your credit card is charged back and the transaction is reversed, your account will be cancelled and all of your websites, data, details, emails, and other information will be erased instantly.
                    </p>
                    <p>
                        Regular updates of details: We make use of the most up-to-date contact information we have. We will not be held liable if you do not receive our notices owing to a change of contact address (which you have not updated with our database). Clients are solely responsible for keeping information up to date We are not liable for any actions done as a result of the client’s failure to receive or respond to Iwebcode’s warnings.
                    </p>
                    <p>
                        Maltreatment of system and network – System or association security violations are prohibited. These actions may result in criminal and civil liability. For any behaviour designed to leak or attempt to leak in network security, we will initiate a criminal complaint (importantly including below mentioned) Prologue of malicious programmes in our network or on any of our servers. Viruses and worms are common examples. Flood pings, port scans, packet spoofing, and faked routing information are among the “disruptions’ ‘ that can occur. Performing security breaches or interfering with Internet communication. Approaching data that the client is not expected to receive is considered a security breach. This also includes cataloguing into a server or account that the client is not authorised to use. Any network monitoring that captures data not intended for the customer’s server is susceptible to execution. Users are not permitted to interrupt or disable any terminal or account using any software, script, or command. The creation of an active?? full-time connection to a company-provided account through the use of artificial techniques such as programming, software, or any other manner. Any attempt to circumvent or adjust monitoring, utilisation reporting, bandwidth tracking, or other similar acts that complicate the company’s traditional operating functions, including but not limited to editing, removing, or otherwise manipulating or tampering with the company’s log files. Any action taken by the company, in its exclusive discretion, will have a detrimental impact on the firm’s operations or reflect adversely on it. Some behaviour that the Company considers to be an inefficient use of resources, bad business practices, or otherwise intolerable.
                    </p>
                    <h1>Proscribes Scripts and Program –&nbsp;</h1>
                    <p>
                        All scripts must follow the same set of common rules. Read on to learn the following rules: The time a script spends on the processor should not exceed a few seconds, or it will be eliminated. Scripts are not permitted to consume a significant amount of system resources.
                    </p>
                    <p>
                        If the script uses a lot of RAM or processor, the account will be suspended until a better solution is found. Similarly, if a site was discovered to be taking 10% of the system’s resources, it would be immediately suspended while a replacement solution was sought.
                    </p>
                    <p>
                        Scripts are not permitted on linked or external sites. Scripts are not permitted on the Iwebcode’s server. Violations of this policy may result in immediate account suspension without prior notice. On the IWEBCODE server, resource incentive programmes such as chat room scripts written in Java or Perl are not permitted.
                    </p>
                    <h1>Loss Of Backup And Data –&nbsp;</h1>
                    <p>
                        Use of our services is completely at your own risk. Iwebcode is not responsible for any data or files that are stored on your account. You acknowledge that you are responsible for data transfer, files, and the correct backup of data and files stored on the iWeb Server. We have the right to make weekly backups of data that has changed or is new. These backups are to restore the server. Clients are completely responsible for backing up their files, which can be done with ease using the Backup Manage?? feature included in each control panel. SCRIPTS FOR CGI It is not permitted to share scripts with domains that are not hosted by Iwebcode. Any CGI scripts that are suspected of negatively impacting our server’s performance or network integrity will be closed immediately and without warning. The use of CGI must be accompanied by Iwebcode’s explicit permission. Hosting for Mailing Lists We do not allow email lists to be hosted on our servers. Any mailing lists discovered on our servers will be removed without warning. On any of Iwebcode’s servers, pornography or any other sex-related marketing is strictly prohibited. This includes any site that contains sexual content or links to adult content on other sites. The same is true for websites that promote illegal conduct or are in any manner harmful to Ink Web Solutions or any other internet server. The prohibition extends to links to such materials as well.
                    </p>
                    <h1>Support –&nbsp;</h1>
                    <p>
                        Support for problems and issues with the control panel will be provided for specific constraints, such as adding or deleting accounts, DNS functionality, changing passwords, and so on. Third-party software such as PHP-Nuke, Advanced Guest Book, phpBB, osCommerce, and others will receive limited support. Furthermore, Iwebcode will not accept any Free Add-on scripts on the control panel or any software errors or defects reported by a third-party member. For any form of help, they must be reported directly to the programme suppliers. Iwebcode is not concerned with web creation, third-party scripts and software such as installation, maintenance, and compatibility, and/or current day-to-day administrative activities such as email accounts, domains, and establishing new users. However, with its Ink Web Solutions (which includes SSL Cert, clientexec, and other services), Iwebcode may assist with initial custom setup or set up of the server, including email accounts, domains, establishing new users, and software modifications, without charging any additional fees.
                    </p>
                    <p>
                        Installation of third party software – If users instal third-party software in their web space, they are solely responsible. If a hack or odd activity is discovered as a result of software security flaws, the account will be terminated without warning. Responsibilities are defined and their extent is limited. Any damage, including consequential or incidental losses, caused by the unavailability or downtime of our servers. Iweb Code will not be liable for any claims for damages, including incidental and consequential damages, that may emerge as a result of our servers going offline or unavailable for any reason. Iwebcode is not liable for any claim recompense, including incidental or consequential damages, resulting from the suspension or removal of any website from any of our servers. In such circumstances, immediate cessation of services must be the final or utmost limit.
                    </p>
                    <h1>Stipulation and Disclaimer –&nbsp;</h1>
                    <p>
                        In the event of data loss, crashes, or system failure/downtime, Iwebcode is not liable. Furthermore, we are not liable for any estimated earnings that a customer may generate as a result of the website’s operation. Because Iwebcode resells some of its services, some of the tools, routing, software, and programming we utilise are not owned or produced by Iwebcode. Iwebcode accepts no responsibility for how our clients’ accounts are used. Failure to comply with any terms or conditions will result in the account being deactivated without warning. Iwebcode reserves the right to cancel or deactivate any account based solely on the rules set forth by the company, and this decision can be made without prior warning or notification. Furthermore, we reserve the right to update or amend these policies, guidelines, and disclaimers at any time and without previous notice. At any time, Iwebcode reserves the right to update or amend the Terms and Conditions. (This information is already available on our website.)
                    </p>
                    <h1>Copyright Policy –&nbsp;</h1>
                    <p>
                        By copyright law and other intellectual property laws, Iwebcode has exclusive authorization to protect all information, material, services, and contents. Reproductions of any information, material, services, or contents could result in legal action under copyright laws.
                    </p>
                    <p>Guidelines for use of Sites information and Materials-</p>
                    <p>
                        The following conditions apply to the viewing, downloading, printing, and dissemination of material and information available on Iwebcode: The following conditions apply to the viewing, downloading, printing, and dissemination of material and information available on Iwebcode: It is completely banned to remove any proprietary notice or copyright from documents. Iwebcode reserves the right to refuse to print, read, download, distribute, or circulate any content posted to Iwebcode at any time, without notice.
                    </p>
                    <p>
                        The rights you have been granted are a licence, not a transfer of ownership. The above-mentioned rights to read, download, print, and distribute the content available on Iwebcode do not apply to the site’s design and layout. Iwebcode is protected by trade dress and other regulations, thus it is not permitted to be duplicated in its full or part.
                    </p>
                    <h1>Warranty-</h1>
                    <p>
                        As further established in a formal agreement between you and Iwebcode, all warranties and disclaimers are excluded. All of the materials and services on this site are provided “as is,” with no express or implied warranties, including but not limited to the implicit warranties of merchantability or fitness for a certain purpose, or the guarantee of non-contravention. Without limiting the foregoing, Iwebcode makes no guarantee that: The services and materials will be strictly by your needs. Services and supplies must be faultless, secure, uninterruptible, and delivered on time. Services and materials and services accuracy, efficacy, and dependability. Iwebcode does not guarantee that any software fault will be corrected immediately. A few circumstances or jurisdictions do not allow ambiguous warranties or time limits on the term of the warranty. As a result, the limitations indicated above may not apply to you to the acceptable level. Please be aware of any implied guarantee that is limited to a maximum of 90 days (ninety days). Iwebcode does not guarantee the accuracy or timeliness of data or information. Iwebcode is not responsible for any omissions or errors in the information,documents, or resources. This site may have technical problems, inaccuracies, typos, or other mistakes.
                    </p>
                    <p>
                        In any event, Iwebcode and its affiliates are not liable to any third party for any special, disciplinary, accompanying, indirect, or consequential damages. While downloading information from this site, Iwebcode is not responsible for any damage to your computer system or data loss. Any data loss / technical issues would be the responsibility of the user. The links on this site are not within the control of Iwebcode. As a result, we do not represent or endorse any of them.
                    </p>
                    <h1>Trademark –&nbsp;</h1>
                    <p>
                        Iwebcode owns all of the logos, trademarks, and service marks displayed on this website, and any personal or commercial use of them is strictly prohibited without Iwebcode’s express consent.
                    </p>
                    <h1>Privacy Policy –</h1>
                    <p>
                        All of the information you supply will be used in compliance with applicable laws. Without your written authorization, the information/data you supply will not be shared with any external organisation or used for any other commercial purpose. It would, however, be open to all of Iwebcode’s divisions, related companies, and initiatives. Temporary cookies are used to make it easier to utilise the services, to maintain the continuity of a visit, and to aid navigation on the site. Only on your written request will your data/information be updated, modified, or deleted.
                    </p>
                    <h1>Payment and Refund Issue –&nbsp;</h1>
                    <p>
                        All payments will be taken using Iwebcode’s usual payment methods. There is no provision for any form of full or partial refund because we at Iwebcode maintain high interactions with our clients and carry out the complete procedure with their approval. We mention here that the paid amount would not be refunded in any circumstances. Once an order is placed it cannot be cancelled as it is sent for processing as soon as it is placed.
                    </p>
                    <p>
                        Your personal preferences may have shifted over time and cannot be used as a basis for a refund or chargeback. If Iwebcode agrees to return any payment after careful review in some situations, it will give 48 hours for the site, domain, and other items to be moved.
                    </p>
                    <p>
                        All essential credentials and passwords for FTP, Domain and other services will be provided to ensure a smooth transition to the service provider or you (if you decide to though we strongly recommend that you go with an expert service provider).
                    </p>
                    <p>
                        To avoid any problem and technological concerns, the company will not supply the content on any other medium, such as CD, pen drive, or moveable drives.
                    </p>
                    <p>You would be fully liable for the login credentials and for the content of the sites provided if you gave this information.</p>
                    <p>
                        All payments for Iwebcode services must be made only to “Iwebcode”. We have not permitted any people or organisation to accept payments for any services supplied by Iwebcode under any other name (i.e. any other individual or company name) You should be aware that Iwebcode will not be held liable for any losses incurred as a result of your business dealings with such fraudulent individuals or organisations under any circumstances.
                    </p>
                    <h1>Matter of Disputes and Jurisdictional Aspects –</h1>
                    <p>
                        Any legal disagreement involving Ink Web Solutions will be resolved only in Chandigarh’s jurisdiction. All legal concerns are only subject to the current laws in force in Chandigarh and are only subject to the jurisdiction of Chandigarh courts.
                    </p>
                    <p>
                        Government Law and Jurisdiction – This Agreement, as well as any dispute or matter arising from the Member’s or Company’s use of Iwebcode on an incidental basis, is governed by Indian law, and the Member and Company hereby submit to the exclusive jurisdiction of the courts in Chandigarh, India. The complete agreement between you and Iwebcode regarding the use of the Site and/or the Service is contained in this Agreement, which you accepted upon the usage of the Site and further affirmed by becoming a Member of the Iwebcode. The remaining portion of this Agreement will remain in full force and effect if any term of this Agreement is found to be invalid. We hope that the above information will help you make the most of the facilities and services provided by Iwebcode. as well as strengthening our business ties. Team Iwebcode extends its warmest welcome and stands ready to assist you in providing the best available solutions.
                    </p>
                </div>
            </Div>
        </Layout>
    )
}

export default TermsOfUse